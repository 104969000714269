import { ServerConfig } from './config.interface';

const sbcrConfig: ServerConfig = {
  id: 'sbcr',
  code: 'ski_a_bike_centrum_radotín',
  displayPartnerPages: true,
  isBiometricDataEnabled: true,
  cssTheme: 'sbcr',
  modalTypeToSlugMapping: {
    'gdpr': 'zasady-ochrany-osobnich-udaju',
    'biometric': 'predani-biometrickych-udaju',
    'terms': 'obchodni-podminky'
  },
  data: {
    homepage: {
      static_page_tiles: {
        how_it_works: {
          title: 'Nejčastější<br>otázky',
          link: '/static/faq-pujceni-lyzi',
          active: true
        },
        delivery_methods: {
          title: 'Ski a Bike<br>Centrum<br>Radotín',
          link: '/static/pujcovna-sbcr',
          active: true
        },
        contact: {
          title: 'Kontakty',
          link: '/static/zpusoby-doruceni',
          active: true
        }
      }
    },
    product: {
      product_detail_tiles: {
        delivery_methods: {
          title: 'Pickup points',
          link: '/static/pujcovna-sbcr',
          active: true
        },
        contacts: {
          title: 'Contacts',
          link: '/static/zpusoby-doruceni',
          active: true
        }
      }
    }
  },
  imageToPreload: [],
  faviconList: [
    "<link rel='icon' type='image/x-icon' href='/assets/favicon-cyklo.ico' sizes='32x32'>"
  ]
};

export default sbcrConfig;
